import { createApp } from "vue";
import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element";
import { ElMessage } from "element-plus";
import axios from "axios";
// import echarts from 'echarts';

import { installFilters } from "@/utils/typeUtil.js"; //全局查找通道类型type
const app = createApp(App);
installFilters(app);

// app.prototype.$echarts = echarts;

// 注册多个过滤器
import vfilter from "@/utils/vfilter.js";
app.config.globalProperties.$filters =vfilter


// 请求拦截
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TOKEN");
    if (!config.headers.hasOwnProperty("Authorization") && token) {
      config.headers = {
        "X-Access-Token": token,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let timer = null;

// 响应拦截，判断token失效跳转
axios.interceptors.response.use(
  (response) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (
        response.data.statusCode === "51000" ||
        response.statusCode === "51000"
      ) {
        ElMessage.success("TOKEN失效,请重新登录!!!");
        localStorage.removeItem("TOKEN");
        router.push("/login");
      }
      timer = null;
    }, 1000);
    return response;
  },
  (error) => {
    ElMessage.warning("当前网络不稳定,请稍后再试");
    router.push("/dashboard");
    return Promise.reject(error);
  }
);

installElementPlus(app);

app
  .use(store)
  .use(router)
  .mount("#app");

export const appRouter = router;
