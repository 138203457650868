<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import DevicePixelRatio from './utils/devicePixelRatio'
export default {
  name: "app",
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    };
  },
  created() {
    this.$nextTick(() => {
      // this.getIp();
      // new DevicePixelRatio().init() // 固定缩放的方法
      window.onload = function () {
        // 控制浏览器缩放
        // document.body.style.zoom = "75%";
      }
    })

    // 禁用浏览器ctrl +- 缩放<br>  
    const keyCodeMap = {
      91: true, // command
      61: true,
      107: true, // 数字键盘 +
      109: true, // 数字键盘 -
      173: true, // 火狐 - 号
      187: true, // +
      189: true // -
    }
    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function (event) {
      const e = event || window.event
      const ctrlKey = e.ctrlKey || e.metaKey
      if (ctrlKey && keyCodeMap[e.keyCode]) {
        e.preventDefault()
      } else if (e.detail) { // Firefox
        event.returnValue = false
      }
    }
    // 覆盖鼠标滑动
    // 禁用鼠标滚轮缩放
    // document.body.addEventListener('wheel', (e) => {
    //   if (e.ctrlKey) {
    //     if (e.deltaY < 0) {
    //       e.preventDefault()
    //       return false
    //     }
    //     if (e.deltaY > 0) {
    //       e.preventDefault()
    //       return false
    //     }
    //   }
    // }, { passive: false })
    // 禁用 浏览器工具栏 点击+- 缩放页面<br>　　

  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
