import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "系统首页",
        },
        beforeEnter: (to, from, next) => {
          // const myComponent = this.$refs.myComponent;
          // console.log("from首页", from);
          // console.log("to首页", to);
          // myComponent.myMethod(); // 调用组件的方法
          next(); // 继续路由跳转
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "../views/dashboard.vue"
          ),
      },
      //  {
      //     path: "/table",
      //     name: "basetable",
      //     meta: {
      //         title: '表格'
      //     },
      //     component: () => import(
      //         /* webpackChunkName: "table" */
      //         "../views/BaseTable.vue")
      // }, {
      //     path: "/charts",
      //     name: "basecharts",
      //     meta: {
      //         title: '图表'
      //     },
      //     component: () => import(
      //         /* webpackChunkName: "charts" */
      //         "../views/BaseCharts.vue")
      // }, {
      //     path: "/form",
      //     name: "baseform",
      //     meta: {
      //         title: '表单'
      //     },
      //     component: () => import(
      //         /* webpackChunkName: "form" */
      //         "../views/BaseForm.vue")
      // }, {
      //     path: "/tabs",
      //     name: "tabs",
      //     meta: {
      //         title: 'tab标签'
      //     },
      //     component: () => import(
      //         /* webpackChunkName: "tabs" */
      //         "../views/Tabs.vue")
      // },

      //新增
      // {
      //     path: '/trafficAgent',
      //     name: 'trafficAgent',
      //     meta: {
      //         title: '话务管理'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/trafficAgent.vue')
      // },
      {
        path: "/callLog",
        name: "callLog",
        meta: {
          title: "通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/callLog.vue"
          ),
      },
      {
        path: "/corporateConsumption",
        name: "corporateConsumption",
        meta: {
          title: "企业历史消费",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/corporateConsumption.vue"
          ),
      },
      {
        path: "/yd1Log",
        name: "yd1Log",
        meta: {
          title: "移动1通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/yd1Log.vue"
          ),
      },
      {
        path: "/cqLog",
        name: "cqLog",
        meta: {
          title: "重庆线路通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/newCallList/cqLog.vue"
          ),
      },
      {
        path: "/wxLog",
        name: "wxLog",
        meta: {
          title: "无锡线路通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/newCallList/wxLog.vue"
          ),
      },

      {
        path: "/yd3Log",
        name: "yd3Log",
        meta: {
          title: "移动3通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/yd3Log.vue"
          ),
      },
      {
        path: "/yd4Log",
        name: "yd4Log",
        meta: {
          title: "南京线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/yd4Log.vue"
          ),
      },
      {
        path: "/yd5Log",
        name: "yd5Log",
        meta: {
          title: "移动5通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/yd5Log.vue"
          ),
      },
      {
        path: "/ydaxLog",
        name: "ydaxLog",
        meta: {
          title: "移动AX通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydaxLog.vue"
          ),
      },
      {
        path: "/dxLog",
        name: "dxLog",
        meta: {
          title: "电信通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dxLog.vue"
          ),
      },
      {
        path: "/hLog",
        name: "hLog",
        meta: {
          title: "H通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/hLog.vue"
          ),
      },
      {
        path: "/sLog",
        name: "sLog",
        meta: {
          title: "H通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/sLog.vue"
          ),
      },
      {
        path: "/dLog",
        name: "dLog",
        meta: {
          title: "D通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dLog.vue"
          ),
      },
      {
        path: "/xLog",
        name: "xLog",
        meta: {
          title: "X通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/xLog.vue"
          ),
      },
      {
        path: "/yd6Log",
        name: "yd6Log",
        meta: {
          title: "6号线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/yd6Log.vue"
          ),
      },
      {
        path: "/sipLog",
        name: "sipLog",
        meta: {
          title: "SIP通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/sipLog.vue"
          ),
      },
      {
        path: "/ydTwoLog",
        name: "ydTwoLog",
        meta: {
          title: "2号线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydTwoLog.vue"
          ),
      },
      {
        path: "/newYdTwoLog",
        name: "newYdTwoLog",
        meta: {
          title: "新2号线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/newYdTwoLog.vue"
          ),
      },

      {
        path: "/threeHxLog",
        name: "threeHxLog",
        meta: {
          title: "3号线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/threeHxLog.vue"
          ),
      },
      {
        path: "/oneHxLog",
        name: "oneHxLog",
        meta: {
          title: "1号线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/oneHxLog.vue"
          ),
      },
      {
        path: "/lygLog",
        name: "lygLog",
        meta: {
          title: "连云港YT线通话记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/lygLog.vue"
          ),
      },
      {
        path: "/qualityCallLog",
        name: "qualityCallLog",
        meta: {
          title: "质检结果",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/qualityCallLog.vue"
          ),
      },
      {
        path: "/qualityCallLogDanger",
        name: "qualityCallLogDanger",
        meta: {
          title: "高危质检结果",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/qualityCallLogDanger.vue"
          ),
      },
      {
        path: "/manualQualityInspection",
        name: "manualQualityInspection",
        meta: {
          title: "人工质检结果",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/manualQualityInspection.vue"
          ),
      },

      {
        path: "/qualityWords",
        name: "qualityWords",
        meta: {
          title: "质检敏感词",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/qualityWords.vue"
          ),
      },
      {
        path: "/qualityOperators",
        name: "qualityOperators",
        meta: {
          title: "质检员管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/qualityOperators.vue"
          ),
      },

      {
        path: "/paramVos",
        name: "paramVos",
        meta: {
          title: "VOS质检管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/paramVos.vue"
          ),
      },
      // {
      //     path: '/xnum',
      //     name: 'xnum',
      //     meta: {
      //         title: 'x号码'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/xnum.vue')
      // },
      // {
      //     path: '/xnumimport',
      //     name: 'xnumimport',
      //     meta: {
      //         title: 'x号码导入'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/xnumimport.vue')
      // },

      //呼叫中心
      {
        path: "/userTop",
        name: "userTop",
        meta: {
          title: "落地管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/userTop.vue"
          ),
      },
      //呼叫中心APP端
      {
        path: "/userTopApp",
        name: "userTopApp",
        meta: {
          title: "落地管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/userTopApp.vue"
          ),
      },
      {
        path: "/agent",
        name: "agent",
        meta: {
          title: "代理管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agent.vue"
          ),
      },
      {
        path: "/agentApp",
        name: "agentApp",
        meta: {
          title: "代理管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentApp.vue"
          ),
      },
      {
        path: "/enterprise",
        name: "enterprise",
        meta: {
          title: "企业管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterprise.vue"
          ),
      },
      {
        path: "/enterpriseApp",
        name: "enterpriseApp",
        meta: {
          title: "企业管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterpriseApp.vue"
          ),
      },
      {
        path: "/enterprise-operators",
        name: "enterprise-operators",
        meta: {
          title: "企业管理-客服",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterprise-operators.vue"
          ),
      },
      {
        path: "/companyQuestionTake",
        name: "companyQuestionTake",
        meta: {
          title: "代理问题记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/companyQuestionTake.vue"
          ),
      },
      {
        path: "/enterpriseDanger",
        name: "enterpriseDanger",
        meta: {
          title: "危机企业管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterpriseDanger.vue"
          ),
      },
      {
        path: "/uuidStop",
        name: "uuidStop",
        meta: {
          title: "企业定时报停管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/uuidStop.vue"
          ),
      },
      {
        path: "/centerNum",
        name: "centerNum",
        meta: {
          title: "南京线中间号管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/centerNum.vue"
          ),
      },
      {
        path: "/dailyReport",
        name: "dailyReport",
        meta: {
          title: "话单日报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dailyReport.vue"
          ),
      },
      {
        path: "/overview",
        name: "overview",
        meta: {
          title: "账单概览",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/overview.vue"
          ),
      },
      {
        path: "/overview_fz",
        name: "overview_fz",
        meta: {
          title: "账单概览",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/overview_fz.vue"
          ),
      },

      {
        path: "/dailyReport-operators",
        name: "dailyReport-operators",
        meta: {
          title: "话单日报表-客服",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dailyReport-operators.vue"
          ),
      },
      {
        path: "/monthlyReport",
        name: "monthlyReport",
        meta: {
          title: "月报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/monthlyReport.vue"
          ),
      },
      {
        path: "/monthlyReport-operators",
        name: "monthlyReport-operators",
        meta: {
          title: "月报表-客服",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/monthlyReport-operators.vue"
          ),
      },
      {
        path: "/logTable",
        name: "logTable",
        meta: {
          title: "实时报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/logTable.vue"
          ),
      },
      {
        path: "/lt2NumManage",
        name: "lt2NumManage",
        meta: {
          title: "联通2号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/lt2NumManage.vue"
          ),
      },
      {
        path: "/xphoneOneX",
        name: "xphoneOneX",
        meta: {
          title: "联通1号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/xphoneOneX.vue"
          ),
      },
      // {
      //     path: '/accountMigration',
      //     name: 'accountMigration',
      //     meta: {
      //         title: '账户迁移'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/accountMigration.vue')
      // },
      {
        path: "/seats",
        name: "seats",
        meta: {
          title: "坐席管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/seats.vue"
          ),
      },
      {
        path: "/remindLog",
        name: "remindLog",
        meta: {
          title: "提醒记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/remindLog.vue"
          ),
      },
      {
        path: "/uuidCustom",
        name: "uuidCustom",
        meta: {
          title: "企业自定义",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/uuidCustom.vue"
          ),
      },

      {
        path: "/renew",
        name: "renew",
        meta: {
          title: "企业待续费",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/renew.vue"
          ),
      },
      // {
      //     path: '/rechargeMassage',
      //     name: 'rechargeMassage',
      //     meta: {
      //         title: '充值信息'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/rechargeMassage.vue')
      // },
      // {
      //     path: '/consumptionReport',
      //     name: 'consumptionReport',
      //     meta: {
      //         title: '企业消费报表'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/consumptionReport.vue')
      // },
      // {
      //     path: '/gatewayChargeReport',
      //     name: 'gatewayChargeReport',
      //     meta: {
      //         title: '网关消费报表'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/gatewayChargeReport.vue')
      // },
      // {
      //     path: '/callListControl',
      //     name: 'callListControl',
      //     meta: {
      //         title: '话单监控'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/callListControl.vue')
      // },
      {
        path: "/audit",
        name: "audit",
        meta: {
          title: "企业申请",
        },
        beforeEnter: (to, from, next) => {
          // const myComponent = this.$refs.myComponent;
          // console.log("from", from);
          // console.log("to", to);
          // myComponent.myMethod(); // 调用组件的方法
          next(); // 继续路由跳转
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/audit.vue"
          ),
        // http://syshb.cddmt.cn/login
      },
      // {
      //     path: '/stopMassage',
      //     name: 'stopMassage',
      //     meta: {
      //         title: '挂机短信'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/stopMassage.vue')
      // },
      {
        path: "/enterpriseRecharge",
        name: "enterpriseRecharge",
        meta: {
          title: "企业充值信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterpriseRecharge.vue"
          ),
      },
      {
        path: "/enterpriseRechargeApp",
        name: "enterpriseRechargeApp",
        meta: {
          title: "企业充值信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/enterpriseRechargeApp.vue"
          ),
      },
      {
        path: "/operationLog",
        name: "operationLog",
        meta: {
          title: "企业操作日志信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/operationLog.vue"
          ),
      },
      {
        path: "/agentRecharge",
        name: "agentRecharge",
        meta: {
          title: "代理充值信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentRecharge.vue"
          ),
      },
      {
        path: "/agentRechargeApp",
        name: "agentRechargeApp",
        meta: {
          title: "代理充值信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentRechargeApp.vue"
          ),
      },
      {
        path: "/userTopRecharge",
        name: "userTopRecharge",
        meta: {
          title: "落地充值信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/userTopRecharge.vue"
          ),
      },
      {
        path: "/agentTicket",
        name: "agentTicket",
        meta: {
          title: "开票记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentTicket.vue"
          ),
      },

      {
        path: "/vos",
        name: "vos",
        meta: {
          title: "VOS账户/报表",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/vos.vue"
          ),
      },
      {
        path: "/agentManagement",
        name: "agentManagement",
        meta: {
          title: "联通AX亲情管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentManagement.vue"
          ),
      },

      {
        path: "/agentManagement2",
        name: "agentManagement2",
        meta: {
          title: "S2亲情管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/agentManagement2.vue"
          ),
      },
      // {
      //     path: '/userRecharge',
      //     name: 'userRecharge',
      //     meta: {
      //         title: '用户充值'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/userRecharge.vue')
      // },
      // {
      //     path: '/enterpriseBalance',
      //     name: 'enterpriseBalance',
      //     meta: {
      //         title: '企业余额'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/enterpriseBalance.vue')
      // },

      {
        path: "/ydTwoAppid",
        name: "ydTwoAppid",
        meta: {
          title: "2号线密钥",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydTwoAppid.vue"
          ),
      },
      {
        path: "/new2NumberManage",
        name: "new2NumberManage",
        meta: {
          title: "new2中间号管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/new2NumberManage.vue"
          ),
      },
      {
        path: "/xianLuNumManage",
        name: "xianLuNumManage",
        meta: {
          title: "线路中间号管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/xianLuNumManage.vue"
          ),
      },
      {
        path: "/xianLuNumManageApp",
        name: "xianLuNumManageApp",
        meta: {
          title: "线路中间号管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/xianLuNumManageApp.vue"
          ),
      },
      {
        path: "/ydTwoNewAppid",
        name: "ydTwoNewAppid",
        meta: {
          title: "新2号线密钥",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydTwoNewAppid.vue"
          ),
      },
      {
        path: "/lt4NumManage",
        name: "lt4NumManage",
        meta: {
          title: "联通4号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/lt4NumManage.vue"
          ),
      },
      {
        path: "/dxNumManage",
        name: "dxNumManage",
        meta: {
          title: "电信号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dxNumManage.vue"
          ),
      },
      {
        path: "/hNumManage",
        name: "hNumManage",
        meta: {
          title: "H号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/hNumManage.vue"
          ),
      },
      {
        path: "/sNumManage",
        name: "sNumManage",
        meta: {
          title: "S号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/sNumManage.vue"
          ),
      },
      {
        path: "/WuxiWhitening",
        name: "WuxiWhitening",
        meta: {
          title: "无锡加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "@/views/WuxiWhitening.vue"
          ),
      },
      {
        path: "/WuxiWhiteningUpstream",
        name: "WuxiWhiteningUpstream",
        meta: {
          title: "无锡加白上游",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "@/views/WuxiWhiteningUpstream.vue"
          ),
      },

      {
        path: "/sipNumManage",
        name: "sipNumManage",
        meta: {
          title: "SIP号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/sipNumManage.vue"
          ),
      },
      {
        path: "/dWhitening",
        name: "dWhitening",
        meta: {
          title: "线路号码加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dWhitening.vue"
          ),
      },
      {
        path: "/dWhiteningCount",
        name: "dWhiteningCount",
        meta: {
          title: "线路号码加白次数设置",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/dWhiteningCount.vue"
          ),
      },
      {
        path: "/fsSendSms",
        name: "fsSendSms",
        meta: {
          title: "D发送短信号码状态查询",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/fsSendSms.vue"
          ),
      },
      {
        path: "/blackList",
        name: "blackList",
        meta: {
          title: "黑名单管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/blackList.vue"
          ),
      },
      {
        path: "/callMessage",
        name: "callMessage",
        meta: {
          title: "报错信息查询",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/callMessage.vue"
          ),
      },
      {
        path: "/Identity",
        name: "Identity",
        meta: {
          title: "验证",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/Identity.vue"
          ),
      },
      {
        path: "/blackNumManage",
        name: "blackNumManage",
        meta: {
          title: "黑名单号码管理",
        },
        component: () =>
          import(/* webpackChunkName: "404" */ "../views/blackNumManage.vue"),
      },

      {
        path: "/ipWhite",
        name: "ipWhite",
        meta: {
          title: "ip加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ipWhite.vue"
          ),
      },
      
      {
        path: "/ipWhiteApp",
        name: "ipWhiteApp",
        meta: {
          title: "ip加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ipWhiteApp.vue"
          ),
      },
      // {
      //   path: "/param",
      //   name: "param",
      //   meta: {
      //     title: "密钥管理",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "404" */
      //       "../views/param.vue"
      //     ),
      // },
      {
        path: "/ydTwoWhitening",
        name: "ydTwoWhitening",
        meta: {
          title: "系统线路号码加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydTwoWhitening.vue"
          ),
      },
      {
        path: "/ydSixWhitening",
        name: "ydSixWhitening",
        meta: {
          title: "甘肃号码加白",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/ydSixWhitening.vue"
          ),
      },
      {
        path: "/CQnumManage",
        name: "CQnumManage",
        meta: {
          title: "重庆号码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/CQnumManage.vue"
          ),
      },
      {
        path: "/uuidTypeOpen",
        name: "uuidTypeOpen",
        meta: {
          title: "线路开关管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/uuidTypeOpen.vue"
          ),
      },
      {
        path: "/uuidTypeOpenApp",
        name: "uuidTypeOpenApp",
        meta: {
          title: "线路开关管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "404" */
            "../views/uuidTypeOpenApp.vue"
          ),
      },
      //系统管理
      // {
      //     path: '/resetPassword',
      //     name: 'resetPassword',
      //     meta: {
      //         title: '修改密码'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/resetPassword.vue')
      // },

      //山东移动
      // {
      //     path: '/accountAdmin',
      //     name: 'accountAdmin',
      //     meta: {
      //         title: '账户管理'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/accountAdmin.vue')
      // },
      // {
      //     path: '/appAdmin',
      //     name: 'appAdmin',
      //     meta: {
      //         title: '应用管理'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/appAdmin.vue')
      // },
      // //YSH
      // {
      //     path: '/app1',
      //     name: 'app1',
      //     meta: {
      //         title: '应用'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/app1.vue')
      // },
      // {
      //     path: '/nums',
      //     name: 'nums',
      //     meta: {
      //         title: '号码池'
      //     },
      //     component: () => import(/* webpackChunkName: "404" */
      //         '../views/nums.vue')
      // },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
    },

    component: () =>
      import(
        /* webpackChunkName: "login" */
        "../views/Login.vue"
      ),
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: "无权限",
    },
    component: () =>
      import(
        /* webpackChunkName: "login" */
        "../views/403.vue"
      ),
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "页面不存在",
    },
    component: () =>
      import(
        /* webpackChunkName: "login" */
        "../views/404.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 商机云PAAS平台`;
  const role = localStorage.getItem("ms_username");
  let pathArr = ["/login", "https://www.baidu.com/?tn=85070231_17_hao_pg"];
  //  // console.log("要去的网页的信息", to);
  //  // console.log("从哪里来的信息", from);
  if (!role && to.path !== "/login") {
    // console.log("跳登录页");
    // if (!role && pathArr.some((v)=>{v==to.path})) {
    next("/login");
  } else if (to.meta.permission) {
    // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    role === "admin" ? next() : next("/403");
  } else {
    next();
  }
});

export default router;
