// utils.js
// export function typeFilter(row, column, cellValue) {
//     const found = options.find((option) => option.type === cellValue);
//     console.log("------typeFilter-",found ? found.label1 : "查无数据-" + cellValue)
//     return found ? found.label1 : "查无数据-" + cellValue;
// }

export const options = [
    // {
    //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
    //   type: 0,
    //   label1: "隐私小号通道",
    // },

    // {
    //     type: 5,
    //     label1: "移动ax通道",
    // },
    {
        type: 1,
        label1: "南京线通道",
    },
    {
        type: 2,
        label1: "移动1通道",
    },
    // {
    //     type: 10,
    //     label1: "移动3通道",
    // },

    // {
    //     type: 13,
    //     label1: "移动5通道",
    // },
    // {
    //   type: 3,
    //   label1: "联通2通道",
    // },
    // {
    //   type: 4,
    //   label1: "联通1通道",
    // },

    // {
    //   type: 6,
    //   label1: "联通3通道",
    // },
    // {
    //   type: 7,
    //   label1: "联通4通道",
    // },
    {
        type: 8,
        label1: "H",
    },
    {
        type: 14,
        label1: "S",
    },

    {
        type: 15,
        label1: "D",
    },

    // {
    //     type: 16,
    //     label1: "S2",
    // },

    {
        type: 17,
        label1: "SIP",
    },
    {
        type: 18,
        label1: "X",
    },
    {
        type: 20,
        label1: "甘肃",
    },
    {
        type: 21,
        label1: "2号线",
    },
    {
        type: 22,
        label1: "3号线",
    },
    {
        type: 23,
        label1: "新2号线",
    },
    {
        type: 24,
        label1: "重庆线路",
    },
    {
        type: 25,
        label1: "1号线",
    },
    {
        type: 26,
        label1: "无锡",
    },
    {
        type: 27,
        label1: "翼信",
    },
    {
        type: 28,
        label1: "连云港YT",
    },
    {
        type: 50,
        label1: "vos",
    },
    {
        type: 51,
        label1: "群呼",
    },
]


export const installFilters = (app) => {
    app.config.globalProperties.$typeFilter = (cellValue) => {
        const found = options.find((option) => option.type === cellValue);
        return found ? found.label1 : "查无数据-" + cellValue;
    };

    app.config.globalProperties.$options = (value) => {
        // 实现 options 的逻辑
        return value;
    };

    // 注册更多的全局过滤器
};
