<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" active-text-color="#fff"
                 unique-opened v-if="!salesman" router>
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index" v-if="setDisplay(item)">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.index }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                    {{ threeItem.title
                                    }}
                                </el-menu-item>
                            </el-submenu>
                            <!-- 把后端传过来的权限（uuid）存到Permission，然后和我自己的页面名字比较，如果相同就隐藏 -->
                            <el-menu-item v-if="!subItem.subs && Permission.indexOf(subItem.index) != -1"
                                          :index="subItem.index"
                                          :key="subItem.index" @click="clear()">{{ subItem.title }}
                                <el-badge :value="value" class="items" max="999999999999999"
                                          v-if="subItem.title == '企业申请' && realname == 'admin'"
                                          style="margin-left: 50px; backgroundcolor: red; z-index: 100">
                                    <el-button size="small" type="primary" style="border: 1px solid #fff;"
                                               @click.stop="check">待审核
                                    </el-button>
                                    <!-- <el size="small" type="primary">待审核</el> -->
                                </el-badge>

                                <!--<el-badge :value="fDangerCount"-->
                                <!--class="items"-->
                                <!--v-if="subItem.title == '高危质检结果' "-->
                                <!--style="margin-left: 50px; backgroundcolor: red; z-index: 100">-->

                                <!--&lt;!&ndash; <el size="small" type="primary">待审核</el> &ndash;&gt;-->
                                <!--</el-badge>-->
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" active-text-color="#fff"
                 unique-opened v-else router>
            <template v-for="item in itemsB">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index" v-if="setDisplay(item)">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.index }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                    {{ threeItem.title
                                    }}
                                </el-menu-item>
                            </el-submenu>
                            <!-- 把后端传过来的权限（uuid）存到Permission，然后和我自己的页面名字比较，如果相同就隐藏 -->
                            <el-menu-item v-if="!subItem.subs && Permission.indexOf(subItem.index) != -1"
                                          :index="subItem.index"
                                          :key="subItem.index" @click="clear()">{{ subItem.title }}
                                <el-badge :value="value" max="999999999999999" class="items"
                                          v-if="subItem.title == '企业申请' && realname == 'admin'"
                                          style="margin-left: 50px; backgroundcolor: red; z-index: 100">
                                    <el-button size="small" type="primary" @click.stop="check">待审核
                                    </el-button>
                                    <!-- <el size="small" type="primary">待审核</el> -->
                                </el-badge>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    // import bus from "../common/bus";
    import axios from "axios";

    export default {
        data() {
            return {
                fDangerCount: 0, //高危个数
                salesman: null,
                value: "",
                status: 1, //待审核的状态码
                realname: "",
                itemsB: [
                    {
                        icon: "el-icon-s-platform",
                        index: "2",
                        title: "操作中心",
                        subs: [
                            {
                                index: "audit",
                                title: "企业申请",
                            },
                        ],
                    },
                ],
                items: [
                    // {
                    //   icon: "el-icon-lx-copy",
                    //   index: "0",
                    //   title: "话务代理管理",
                    //   subs: [
                    //     {
                    //       index: "trafficAgent",
                    //       title: "代理管理"
                    //     },
                    //   ]
                    // },
                    // {
                    //     icon: "el-icon-lx-copy",
                    //     index: "1",
                    //     title: "xb实商",
                    //     subs: [
                    //         {
                    //             index: "callLog",
                    //             title: "通话记录"
                    //         },
                    //         {
                    //             index: "xnum",
                    //             title: "x号码"
                    //         },
                    //         {
                    //           index: "xnumimport",
                    //           title: "x号码导入"
                    //         },
                    //     ]
                    // },

                    {
                        icon: "el-icon-s-platform",
                        index: "2",
                        title: "操作中心",
                        subs: [
                            {
                                index: "userTop",
                                title: "落地管理",
                            },
                            {
                                index: "agent",
                                title: "代理管理",
                            },

                            {
                                index: "enterprise",
                                title: "企业管理",
                            },
                            {
                                index: "enterprise-operators",
                                title: "企业管理-客服",
                            },
                            {
                                index: "companyQuestionTake",
                                title: "代理问题记录",
                            },
                            {
                                index: "enterpriseDanger",
                                title: "危机企业管理",
                            },
                            {
                                index: "uuidStop",
                                title: "企业定时报停管理",
                            },
                            {
                                index: "audit",
                                title: "企业申请",
                            },
                            // {
                            //   index: "accountMigration",
                            //   title: "账户迁移"
                            // },
                            {
                                index: "seats",
                                title: "坐席管理",
                            },
                            {
                                index: "remindLog",
                                title: "提醒记录",
                            },
                            {
                                index: "uuidCustom",
                                title: "企业自定义",
                            },
                            {
                                index: "renew",
                                title: "企业待续费",
                            },
                            // {
                            //   index: "rechargeMassage",
                            //   title: "充值信息"
                            // },
                            // {
                            //   index: "consumptionReport",
                            //   title: "企业消费报表"
                            // },
                            // {
                            //   index: "gatewayChargeReport",
                            //   title: "网关话费报表"
                            // },
                            // {
                            //   index: "callListControl",
                            //   title: "话单监控"
                            // },
                            // {
                            //   index: "codeQuery",
                            //   title: "验证码查询"
                            // },
                            // {
                            //   index: "stopMassage",
                            //   title: "挂机短信"
                            // },
                            // {
                            //   index: "enterpriseBalance",
                            //   title: "企业余额"
                            // },
                        ],
                    },
                    {
                        icon: "el-icon-s-order",
                        index: "3",
                        title: "日志报表",
                        subs: [

                            {
                                index: "dailyReport",
                                title: "话单日报表",
                            },
                            {
                                index: "monthlyReport",
                                title: "话单月报表",
                            },
                            {
                                index: "dailyReport-operators",
                                title: "话单日报表-客服",
                            },
                            {
                                index: "monthlyReport-operators",
                                title: "话单月报表-客服",
                            },
                            {
                                index: "logTable",
                                title: "实时报表",
                            },
                            {
                                index: "overview",
                                title: "账单概览",
                            },
                            {
                                index: "overview_fz",
                                title: "账单概览",
                            },

                            {
                                index: "agentRecharge",
                                title: "代理充值日志查询",
                            },
                            {
                                index: "enterpriseRecharge",
                                title: "企业充值日志查询",
                            },
                            {
                                index: "userTopRecharge",
                                title: "落地充值日志查询",
                            },
                            {
                                index: "corporateConsumption",
                                title: "企业历史消费",
                            },
                            {
                                index: "operationLog",
                                title: "企业操作日志查询",
                            },
                            {
                                index: "agentTicket",
                                title: "开票记录",
                            },

                            {
                                index: "vos",
                                title: "VOS账户/报表",
                            },
                        ],
                    },
                    {
                        icon: "el-icon-s-data",
                        index: "4",
                        title: "通话记录",
                        subs: [
                            {
                                index: "callLog",
                                title: "通话记录",
                            },
                            {
                                index: "yd1Log",
                                title: "移动1通话记录",
                            },
                            {
                                index: "yd3Log",
                                title: "移动3通话记录",
                            },
                            {
                                index: "yd4Log",
                                title: "南京线通话记录",
                            },
                            {
                                index: "yd5Log",
                                title: "移动5通话记录",
                            },
                            {
                                index: "ydaxLog",
                                title: "移动AX通话记录",
                            },
                            {
                                index: "dxLog",
                                title: "电信通话记录",
                            },
                            {
                                index: "hLog",
                                title: "H通话记录",
                            },
                            {
                                index: "sLog",
                                title: "S通话记录",
                            },
                            {
                                index: "dLog",
                                title: "D通话记录",
                            },
                            {
                                index: "xLog",
                                title: "X通话记录",
                            },
                            {
                                index: "yd6Log",
                                title: "6号线通话记录",
                            },
                            {
                                index: "sipLog",
                                title: "SIP通话记录",
                            },
                            {
                                index: "ydTwoLog",
                                title: "2号线通话记录",
                            },
                            {
                                index: "newYdTwoLog",
                                title: "新2号线通话记录",
                            },
                            {
                                index: "threeHxLog",
                                title: "3号线通话记录",
                            },
                            {
                                index: "oneHxLog",
                                title: "1号线通话记录",
                            },
                            {
                                index: "cqLog",
                                title: "重庆线路通话记录",
                            },
                            {
                                index: "wxLog",
                                title: "无锡线路通话记录",
                            },
                            {
                                index: "lygLog",
                                title: "连云港YT线路通话记录",
                            },

                            {
                                index: "qualityCallLog",
                                title: "质检结果",
                            },
                            {
                                index: "qualityCallLogDanger",
                                title: "高危质检结果",
                            },
                            {
                                index: "manualQualityInspection",
                                title: "人工质检结果",
                            },

                            {
                                index: "qualityWords",
                                title: "质检敏感词",
                            },
                            {
                                index: "qualityOperators",
                                title: "质检员管理",
                            },

                            {
                                index: "paramVos",
                                title: "VOS质检管理",
                            },
                        ],
                    },
                    {
                        icon: "el-icon-setting",
                        index: "5",
                        title: "号码管理",
                        subs: [
                            {
                                index: "centerNum",
                                title: "南京线中间号管理",
                            },
                            {
                                index: "agentManagement",
                                title: "移动AX亲情管理",
                            },

                            {
                                index: "agentManagement2",
                                title: "S2亲情管理",
                            },
                            {
                                index: "dxNumManage",
                                title: "电信号码管理",
                            },
                            {
                                index: "hNumManage",
                                title: "H号码管理",
                            },
                            {
                                index: "sNumManage",
                                title: "S号码管理",
                            },
                            {
                                index: "sipNumManage",
                                title: "SIP号码管理",
                            },
                            {
                                index: "dWhitening",
                                title: "线路号码加白",
                            },
                            {
                                index: "dWhiteningCount",
                                title: "线路号码加白次数设置",
                            },
                            {
                                index: "fsSendSms",
                                title: "D发送短信号码状态查询",
                            },
                            {
                                index: "blackList",
                                title: "被叫黑名单管理",
                            },

                            {
                                index: "blackNumManage",
                                title: "主叫黑名单号码管理",
                            },
                            {
                                index: "callMessage",
                                title: "报错信息查询",
                            },
                            {
                                index: "ipWhite",
                                title: "ip加白",
                            },
                            {
                                index: "param",
                                title: "密钥管理",
                            },
                            {
                                index: "WuxiWhitening",
                                title: "无锡加白",
                            },
                            {
                                index: "WuxiWhiteningUpstream",
                                title: "无锡加白上游",
                            },

                            {
                                index: "ydTwoAppid",
                                title: "2号线密钥",
                            },
                            {
                                index: "ydTwoNewAppid",
                                title: "新2号线密钥",
                            },
                            {
                                index: "new2NumberManage",
                                title: "new2中间号管理",
                            },
                            {
                                index: "xianLuNumManage",
                                title: "线路中间号管理",
                            },
                            {
                                index: "ydTwoWhitening",
                                title: "系统线路号码加白",
                            },
                            {
                                index: "ydSixWhitening",
                                title: "甘肃号码加白",
                            },
                            {
                                index: "CQnumManage",
                                title: "重庆号码管理",
                            },
                            {
                                index: "uuidTypeOpen",
                                title: "线路开关管理",
                            },
                        ],
                    },

                    //   {
                    //     icon: "el-icon-lx-copy",
                    //     index: "3",
                    //     title: "系统管理",
                    //     subs: [
                    //       {
                    //         index: "resetPassword",
                    //         title: "修改密码"
                    //       },
                    //     ]
                    //   },

                    //   {
                    //     icon: "el-icon-lx-copy",
                    //     index: "4",
                    //     title: "山东移动",
                    //     subs: [
                    //       {
                    //         index: "accountAdmin",
                    //         title: "账户管理"
                    //       },
                    //       {
                    //         index: "appAdmin",
                    //         title: "应用管理"
                    //       },
                    //     ]
                    //   },
                    // {
                    //   icon: "el-icon-lx-copy",
                    //   index: "5",
                    //   title: "YSH",
                    //   subs: [
                    //     {
                    //       index: "app1",
                    //       title: "应用"
                    //     },
                    //     {
                    //       index: "nums",
                    //       title: "号码池"
                    //     },
                    //   ]
                    // },
                ],
                Permission: [],
                id: null,
            };
        },
        computed: {
            onRoutes() {
                return this.$route.path.replace("/", "");
            },
            collapse() {
                return this.$store.state.collapse;
            },
        },
        mounted() {
            // 判断是否属于跳转pass
            this.salesman = localStorage.getItem("salesman");
        },
       
        created() {
            this.realname = localStorage.getItem("realname");
            this.id = localStorage.getItem("id");

            // 需要隐藏的路由
            let hiddenArr = ['enterpriseRecharge', 'agentRecharge']

            //拿到储存在localStorage中的字段
            //  // console.log(JSON.parse(localStorage.getItem('Permission')))
            let array = JSON.parse(localStorage.getItem("Permission"));
            //将后端传过来的权限循环存到Permission中
            for (let i = 0; i < array.length; i++) {

                // 代理id为161时，隐藏一些功能
                // if (this.id == '161') {
                // hiddenArr.includes(array[i]) ? "" : this.Permission.push(array[i]);
                // } else {
                this.Permission.push(array[i]);
                // }
            }

            // 由前端添加的路由
            // this.Permission.push("corporateConsumption")

            //  // console.log("权限大全", this.Permission)
            if (this.realname == "admin") {
                let arr = ['remindLog','manualQualityInspection', 'companyQuestionTake', 'ydTwoAppid', 'newYdTwoLog', 'WuxiWhitening', 'WuxiWhiteningUpstream', 'new2NumberManage', 'cqLog', 'wxLog']
                // 为admin时由前端添加的路由
                arr.forEach((v) => {
                    this.Permission.push(v);
                })


                this.getNums();
            }

            // 直接添加的路由
            // if (this.realname == "admin" || this.realname == "陈红盈-xn") {
            //     this.getfDangerCount();
            // }
        },
        methods: {
            setDisplay(item) {
                // 设置除了管理员登录时需要隐藏的大模块数组
                let arr = ["号码管理"];
                // let hiddenArr = ["号码管理", '代理充值日志查询', '企业充值日志查询']
                //  // console.log("item", item.title);
                // if (this.realname == "admin") {
                return true;
                // } else {
                //   return !!arr.indexOf(item.title);
                // }


            },


            //获取高危数量
            getfDangerCount() {
                let params = {
                    f_danger_count: 4,
                    shouye_f_danger_count: "1" //确认获取的是次数
                };
                axios
                    .post("api/quality/list-danger", params)
                    .then((res) => {
                        this.fDangerCount = res.data.data;
                    });
            },
            //获取待审核数量
            getNums() {
                axios
                // .post("http://106.14.0.130:9998/vo/uuid-pass/find-count")
                    .post("api/uuid-pass/find-count")
                    .then((res) => {
                        this.value = res.data;
                        // this.value = 120;
                        //  // console.log("原申请数量",this.value);
                    });
            },
            //点击待审核
            check() {
                this.$router.push("/audit");
                localStorage.setItem("status", this.status);
            },
            clear() {
                localStorage.removeItem("status", this.status);
            },
        },
    };
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 60px;
        bottom: 0;
        overflow-y: scroll;
    }

    .el-menu > .is-active > .el-submenu__title > i {
        color: #ccc !important;
        /* color: rgb(32, 160, 255) !important; */
    }

    .el-menu > .is-active > .el-submenu__title > span {
        /* color: rgb(32, 160, 255) !important; */
        color: #ccc !important;
    }

    .is-active {
        /* background-color: greenyellow !important; */
        background-color: rgb(64, 158, 255) !important;
    }

    .sidebar::-webkit-scrollbar {
        width: 0;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }

    .sidebar > ul {
        height: 100%;
    }

    div /deep/ .el-badge__content--primary {
        background-color: red;
    }
</style>
