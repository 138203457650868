<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo" style="color: #fff; margin-left: 20px; font-weight: bold">
      <!-- <div class="logo" style="color: #409eff; margin-left: 20px;font-weight: bold;"> -->
      <img
        src="../assets/img/logo3.png"
        alt=""
        style="width: 40px; height: 40px; margin: -10px 0px"
      />
      商机云PAAS平台
      <span style="margin: 0 20px; font-size: 14px; cursor: pointer"
        >IP地址
        <em
          >「{{ province }}
          <span style="color: red; font-size: 150%">{{ city }}</span>
          」</em
        ></span
      >
    </div>
    <!-- <div class="header-center">
                <div>
                    <p>使用手册</p>
                    <p>在线咨询</p>
                    <p>客服电话:4006232-032</p>
                </div>
            </div> -->
    <div class="header-right">
      <div class="header-user-con">
        <!-- <div class="dep">
          <span style="margin-right: 50px; font-size: 14px; cursor: pointer"
            >您当前ip属地在
            <em
              >「{{ province }} ·
              <span style="color: red; font-size: 150%">{{ city }}</span
              >」</em
            ></span
          >
        </div> -->
        <!-- <el-link type="success" style="margin: 6px;font-size: 14px;color: #fff;" :underline="false"
          @click="sysc()">使用手册</el-link> -->
        <!-- <div style="
                  margin-right: 25px;
                  font-weight: bold;
                  font-size: 14px;
                  cursor: pointer;
                  text-decoration: underline;
                " >
          使用手册
        </div> -->
        <!-- <el-link type="primary" style="margin: 6px;font-size: 14px;color: #fff;" @click="zxzx()">探客躺赚</el-link> -->
        <!--
        <div style="
                  margin-right: 25px;
                  font-weight: bold;
                  font-size: 14px;
                  cursor: pointer;
                " @click="zxzx()">
          探客躺赚
        </div> -->
        <div
          class="el-dropdown-link"
          @click="show_url"
          style="font-size: 12px; margin-right: 25px; font-weight: bold"
          v-if="realname != 'admin'"
        >
          修改地址
        </div>
        <!-- <div style="
                  margin-right: 25px;
                  font-weight: bold;
                  font-size: 12px;
                  cursor: pointer;
                " @click="devApi()">
          开发文档
        </div>

        <div class="el-dropdown-link" @click="showMoney" style="font-size: 12px">
          修改密码
        </div>
        <div class="sx"></div> -->
        <div style="font-size: 14px">
          客服电话:
          <span style="text-decoration: underline; font-weight: bold"
            >4001-886-985</span
          >
        </div>
        <div class="sx"></div>
        <div
          @click="userSetShow = true"
          style="font-size: 12px; cursor: pointer"
        >
          <div
            style="
              height: 36px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span style="font-size: 14px; font-weight: bold"
              >{{ realname ? realname : ms_username }} , 欢迎你</span
            >
            <i class="el-icon-arrow-down"></i>
          </div>
          <!-- loginout() -->
          <!-- 退出 -->
        </div>
        <!-- 下拉 -->
        <!-- <el-dropdown @coactivatedmmand="handleCommand">
                  <span class="el-dropdown-link">
                    欢迎你，{{ userName }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="personal">个人中心</el-dropdown-item>
                    <el-dropdown-item command="logout" @click="loginout()">退出系统</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
      </div>
    </div>
    <!-- 修改密码弹出框 -->
    <el-dialog title="修改密码" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="账户">
          <el-input v-model="ms_username" disabled></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 用户操作弹框 -->
    <div v-if="userSetShow" class="userSetDialog">
      <el-dialog title="操作栏" v-model="userSetShow" center width="20%">
        <div class="main">
          <div class="utilItem">
            <img
              :src="freezeUrl.UserManual"
              style="width: 20px; height: 20px"
              alt=""
            />
            <p @click="sysc()">使用手册</p>
          </div>
          <div class="utilItem">
            <img
              :src="freezeUrl.kaifa"
              style="width: 20px; height: 20px"
              alt=""
            />
            <p @click="devApi()">开发文档</p>
          </div>
          <div class="utilItem">
            <img
              :src="freezeUrl.tktz"
              style="width: 20px; height: 20px"
              alt=""
            />
            <p @click="zxzx()">探客躺赚</p>
          </div>
          <div class="utilItem">
            <img
              :src="freezeUrl.exitpwd"
              style="width: 20px; height: 20px"
              alt=""
            />
            <p @click="showMoney">修改密码</p>
          </div>
          <div class="utilItem">
            <img
              :src="freezeUrl.quit"
              style="width: 20px; height: 20px"
              alt=""
            />
            <p @click="loginout()">退出</p>
          </div>
        </div>
      </el-dialog>
    </div>

    <!-- 修改回调 地址 -->
    <el-dialog
      title="回调地址（某些线路不可用）"
      v-model="editVisible_url"
      @close="editVisible_url_false"
      width="30%"
    >
      <el-form ref="form_url" :model="form_url" label-width="100px">
        <el-form-item label="坐席回调地址">
          <el-row :gutter="20">
            <el-col :span="18">
              <el-input v-model="form_url.seatPhoneUrl"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button
                type="success"
                icon="el-icon-check"
                circle
                v-if="ping_url_button_color"
              ></el-button>
            </el-col>
          </el-row>
          <div style="margin-top: 10px">
            <el-button
              id="ping_url_button"
              @click="ping_seatPhoneUrl(form_url.seatPhoneUrl)"
              >校验地址1</el-button
            >
            <el-button type="warning" @click="clear_url(1)"
              >清空已有地址</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="话单回调地址">
          <el-row :gutter="20">
            <el-col :span="18">
              <el-input v-model="form_url.url"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button
                type="success"
                icon="el-icon-check"
                circle
                v-if="ping_url_buttonTwo_color"
              ></el-button>
            </el-col>
          </el-row>
          <div style="margin-top: 10px">
            <el-button
              id="ping_url_buttonTwo"
              @click="ping_testPhoneUrl(form_url.url)"
              >校验地址2</el-button
            >
            <el-button type="warning" @click="clear_url(2)"
              >清空已有地址</el-button
            >
          </div>
        </el-form-item>
        <!--<el-form-item label="密码">-->
        <!--<el-input v-model="form.password"></el-input>-->
        <!--</el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible_url_false">取 消</el-button>
          <el-button type="primary" @click="saveEdit_url">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      freezeUrl: {
        exitpwd: require("@/assets/miniImg/exitpwd.png"),
        kaifa: require("@/assets/miniImg/kaifa.png"),
        quit: require("@/assets/miniImg/quit.png"),
        UserManual: require("@/assets/miniImg/UserManual.png"),
        tktz: require("@/assets/miniImg/tktz.png"),
      },
      realname: null,
      id: null,
      form: {
        password: "",
      },
      form_url: {}, //回调地址
      editVisible_url: false, //回调地址弹出框
      ping_url_button_color: null,
      ping_url_buttonTwo_color: null,
      ms_username: null,
      editVisible: false,
      fullscreen: false,
      name: "",
      userSetShow: false,
      message: 2,
      money: "",
      city: "",
      province: "",
      indexApi: "http://axb.cddmt.cn:9005/damaitong_api.pdf", //开发文档
      tktz: "http://tktz.cddmt.cn",
      ShiYongShouCe: "http://axb.cddmt.cn:9005/ShiYongShouCe.pptx",
    };
  },
  computed: {
    username() {
      let username = localStorage.getItem("ms_username");
      return username ? username : this.name;
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  created() {
    this.$nextTick(() => {
      this.realname = localStorage.getItem("realname");
      this.ms_username = localStorage.getItem("ms_username");
      this.city = localStorage.getItem("city");
      this.province = localStorage.getItem("province");
    });
  },
  methods: {
    //清空状态地址
    clear_url(num) {
      this.userId = localStorage.getItem("id");
      let params = {
        userId: this.userId,
      };
      if (1 == num) {
        params.seatPhoneUrl = "";
      } else {
        params.url = "";
      }
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-sendurl/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }

          this.editVisible_url = false;
          editVisible_url_false();
        });
    },
    //回调地址 关闭
    editVisible_url_false() {
      let ping_url_button = document.getElementById("ping_url_button");
      ping_url_button.style.backgroundColor = "";
      this.ping_url_button_color = null;

      let ping_url_buttonTwo = document.getElementById("ping_url_buttonTwo");
      ping_url_buttonTwo.style.backgroundColor = "";
      this.ping_url_buttonTwo_color = null;

      this.editVisible_url = false;
    },
    // 第二个检验地址
    ping_testPhoneUrl(url) {
      let ping_url_buttonTwo = document.getElementById("ping_url_buttonTwo");

      if (!url) {
        this.$message.error("地址错误，检验失败");
        return;
      }
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-sendurl/ping-url", url)
        .then((res) => {
          this.form_url.url = res.data.data;

          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            ping_url_buttonTwo.style.backgroundColor = "#67c23a";
            this.ping_url_buttonTwo_color = true;
          } else {
            this.$message.error(res.data.message);
            ping_url_buttonTwo.style.backgroundColor = "#f56c6cc2";
            this.ping_url_buttonTwo_color = false;
          }
        });
    },
    //检验地址
    ping_seatPhoneUrl(url) {
      let ping_url_button = document.getElementById("ping_url_button");
      if (!url) {
        this.$message.error("地址错误，检验失败");
        return;
      }
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-sendurl/ping-url", url)
        .then((res) => {
          this.form_url.seatPhoneUrl = res.data.data;

          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            ping_url_button.style.backgroundColor = "#67c23a";
            this.ping_url_button_color = true;
          } else {
            this.$message.error(res.data.message);
            ping_url_button.style.backgroundColor = "#f56c6cc2";
            this.ping_url_button_color = false;
          }
        });
    },
    // 确认修改 地址
    saveEdit_url() {
      this.userId = localStorage.getItem("id");
      let params = {
        userId: this.userId,
      };

      if (this.form_url.seatPhoneUrl != "") {
        if (!this.ping_url_button_color) {
          this.$message.error("有地址未校验或未通过1");
          return;
        } else {
          params.seatPhoneUrl = this.form_url.seatPhoneUrl;
        }
      }

      if (this.form_url.url != "") {
        if (!this.ping_url_buttonTwo_color) {
          this.$message.error("有地址未校验或未通过2");
          return;
        } else {
          params.url = this.form_url.url;
        }
      }

      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-sendurl/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible_url_false();
        });
    },
    //修改地址 弹出框
    show_url() {
      this.userId = localStorage.getItem("id");
      this.editVisible_url = true;
      let params = {
        userId: this.userId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-sendurl/findOne", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.form_url = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    //使用手册
    sysc() {
      //  // console.log("使用手册");
      window.open(this.ShiYongShouCe, "_blank");
    },
    //在线咨询
    zxzx() {
      window.open(this.tktz, "_blank");
    },
    //开发文档
    devApi() {
      window.open(this.indexApi, "_blank");
    },
    // 修改密码
    showMoney() {
      this.editVisible = true;
    },
    // 确认修改密码
    saveEdit() {
      this.id = localStorage.getItem("id");
      let params = {
        username: this.ms_username,
        id: this.id,
        password: this.form.password,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible = false;
        });
    },
    //退出
    loginout() {
      this.$alert("确认要退出账号吗?", "", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            axios
              // .post("http://106.14.0.130:9998/vo/login/out")
              .post("api/login/out")
              .then((res) => {
                window.localStorage.clear();
                this.$router.push("/login");
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                this.$message.error(res.data.message);
                // console.log(err);
              });
          }
        },
      });
    },
    // 侧边栏折叠
    collapseChage() {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
    // getMoney(){
    //     this.allMoney = this.route.params.allMoney
    // },
  },
  //账户余额请求接口

  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
  //账户信息
  account() {},
};
</script>
<style scoped lang="scss">
::v-deep .userSetDialog {
  .main {
    min-height: 200px;

    .utilItem {
      height: 30px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      box-sizing: border-box;
      border-bottom: 1px solid #e8e8e8;
      padding-right: 20px;

      p {
        font-weight: bold;
        cursor: pointer;
        flex: 1;
        text-align: center;
      }
    }
  }

  .el-dialog {
    border-radius: 15px;

    .el-dialog__header {
      padding: 10px;
      font-weight: bold;
      border-bottom: 1px solid #666;
    }

    .el-dialog__body {
      padding: 10px;
      padding-top: 0px;
    }
  }
}

.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 22px;
  color: #ffffff;
  background-color: rgba(64, 158, 255, 0.95);
}

.header-center {
  width: 500px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.sx {
  content: "";
  /* position: absolute; */
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto 20px;
  width: 1px;
  height: 20px;
  background-color: #999;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}

.header .logo {
  float: left;
  /* width: 250px; */
  line-height: 60px;
}

.header-right {
  float: right;
  padding-right: 20px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: justify;
  border: 0ch;
}

/* 隐藏横线 */
/* .balance{
        border:0ch;

    } */
</style>
