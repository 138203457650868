/**
 * @date 2020-11-06
 * @Description: 全局过滤器
 */
const vfilter = {
  // 全局日期过滤器
  formaDate: function(value, fmt) {
    if (value == "——" || value == "0001-01-01T00:00:00") {
      fmt = "";
    } else if (value) {
      var date = new Date(value);
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "w+": date.getDay(), //星期
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o) {
        if (k === "w+") {
          if (o[k] === 0) {
            fmt = fmt.replace("w", "周日");
          } else if (o[k] === 1) {
            fmt = fmt.replace("w", "周一");
          } else if (o[k] === 2) {
            fmt = fmt.replace("w", "周二");
          } else if (o[k] === 3) {
            fmt = fmt.replace("w", "周三");
          } else if (o[k] === 4) {
            fmt = fmt.replace("w", "周四");
          } else if (o[k] === 5) {
            fmt = fmt.replace("w", "周五");
          } else if (o[k] === 6) {
            fmt = fmt.replace("w", "周六");
          }
        } else if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
    } else {
      fmt = "";
    }
    return fmt;
  },
  setlineType: function(value) {
    if (value == 0) {
      return "隐私小号通道";
    } else if (value == 1) {
      return "南京线";
    } else if (value == 2) {
      return "电信";
    } else if (value == 3) {
      return "联通2";
    } else if (value == 4) {
      return "联通1";
    } else if (value == 5) {
      return "移动ax";
    } else if (value == 6) {
      return "联通3";
    } else if (value == 7) {
      return "联通4";
    } else if (value == 8) {
      return "H";
    } else if (value == 9) {
      return "联通5";
    } else if (value == 10) {
      return "移动3";
    } else if (value == 11) {
      return "联通6";
    } else if (value == 12) {
      return "联通7";
    } else if (value == 13) {
      return "移动5";
    } else if (value == 14) {
      return "S";
    } else if (value == 15) {
      return "D";
    } else if (value == 16) {
      return "S2";
    } else if (value == 17) {
      return "SIP";
    } else if (value == 18) {
      return "X";
    } else if (value == 20) {
      return "甘肃";
    } else if (value == 21) {
      return "2号线";
    } else if (value == 22) {
      return "3号线";
    } else if (value == 23) {
      return "新2号线";
    } else if (value == 24) {
      return "重庆线路";
    } else if (value == 25) {
      return "1号线";
    } else if (value == 26) {
      return "无锡";
    } else if (value == 27) {
      return "翼信";
    } else if (value == 28) {
      return "连云港YT";
    } else {
      return "其它";
    }
  },
};

export default vfilter;
