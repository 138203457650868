<template>
    <div class="floating-window" :style="{ left: position.x + 'px', top: position.y + 'px' }" @mousedown.stop="startDrag">
        <div class="contentBox">
            <div class="priBox">
                <div style="padding-right: 60px;box-sizing: border-box;">
                    <el-tooltip :disabled="tooltipBool" class="item" effect="dark"
                        :content="`今日最大并发${countMax}个,当前并发${count}个`" placement="top">
                        <p style="font-weight: bold;"> <i style="color: rgba(0, 255, 255, 0.638);"
                                class="el-icon-top">Max</i> {{
                                    countMax }} 个</p>
                    </el-tooltip>
                    <p style="font-weight: bold;"> <i style="color: rgba(127, 255, 157, 0.653);" class="el-icon-top">当前</i>
                        {{ count }} 个</p>

                </div>
                <div class="floating-button" @dblclick="query">
                    <el-tooltip :disabled="tooltipBool" class="item" effect="light" content="双击刷新并发信息" placement="top">
                        <el-image style="width: 70px; height: 70px;" @dragstart.prevent :src="urlArr[index]"
                            fit="fill"></el-image>
                    </el-tooltip>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axios from "axios";

export default {
    name: "FloatingWindow",
    data() {
        return {
            index: 0,
            countMax: 0,
            count: 0,
            tooltipBool: false,
            isDragging: false,
            position: { x: 300, y: 100 }, // 初始位置设为 (50, 50) 以避免遮挡内容
            offset: { x: 0, y: 0 },
            urlArr: [require("@/assets/gif/query.gif"), require("@/assets/gif/jiasu.gif"), require("@/assets/gif/yes.gif")]
        };
    },
    created() {
        axios
            .get("api/user/bind-sum", {})
            .then((res) => {
                if (res.data.statusCode == "00000") {
                    this.countMax = res.data.data.countMax
                    this.count = res.data.data.count

                } else {
                    this.$message.error(res.data.message);
                }
            });
    },
    methods: {
        query() {
            if (this.timer) {
                return false
            }
            this.index = 1
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                axios
                    .get("api/user/bind-sum", {})
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            // this.$message.success(res.data.message);
                            this.countMax = res.data.data.countMax
                            this.count = res.data.data.count
                             // console.log("this.countMax", this.countMax);
                            this.index = 2
                            setTimeout(() => {
                                this.index = 0
                            }, 1200)
                        } else {
                            this.index = 0
                            this.$message.error(res.data.message);
                        }
                    });

                this.timer = null
            }, 2000);
        },
        startDrag(event) {
            this.tooltipBool = true
            this.isDragging = true;
            this.offset.x = event.clientX - this.position.x;
            this.offset.y = event.clientY - this.position.y;
            document.addEventListener("mousemove", this.drag);
            document.addEventListener("mouseup", this.stopDrag);
        },
        drag(event) {
            if (this.isDragging) {
                this.position.x = event.clientX - this.offset.x;
                this.position.y = event.clientY - this.offset.y;
            }
        },
        stopDrag() {
            this.tooltipBool = false
            this.isDragging = false;
            document.removeEventListener("mousemove", this.drag);
            document.removeEventListener("mouseup", this.stopDrag);
        },
    },
};
</script>
  
<style>
.floating-window {
    position: fixed;
    width: 200px;
    top: 0;
    right: 0;
    opacity: 0.9;
    z-index: 9999;
    display: inline-block;
}

.contentBox {
    display: inline-block;
}

.priBox {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    min-height: 60px;
    border-radius: 30px;
    padding: 4px 16px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

}

.floating-button {
    cursor: pointer;
    right: -10px;
    min-width: 70px;
    min-height: 70px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.floating-button:active {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.floating-window:hover {
    opacity: 1;
    cursor: grab;
}

.floating-window:active {
    cursor: grabbing;
}
</style>
  