<template>
  <div class="about">
    <v-header />
    <v-sidebar v-show="!dataForm.isAuthentication || realname == 'admin'" />
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <v-tags></v-tags>
      <div class="content">
        <router-view @getsidebarRef="getsidebarRef" v-slot="{ Component }">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </div>
    </div>
    <DraggableResizable
      v-if="realname == 'admin' && !isApp"
    ></DraggableResizable>
  </div>
</template>
<script>
import vHeader from "../components/Header";
import vSidebar from "../components/Sidebar";
import vTags from "../components/Tags.vue";
import DraggableResizable from "../components/DraggableResizable.vue";

export default {
  components: {
    vHeader,
    vSidebar,
    vTags,
    DraggableResizable,
  },
  computed: {
    tagsList() {
      return this.$store.state.tagsList.map((item) => item.name);
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  data() {
    return {
      isApp: false,
      realname: null,
      dataForm: {
        isAuthentication: false,
      },
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.getList();
    if (document.body.clientWidth <= 900) {
      // 手机端适配,自动跳转到对应的手机页面或隐藏功能
      this.isApp = true;
    }
  },
  methods: {
    getsidebarRef(v) {
      this.getList();
      // console.log("调用父组件方法",v);
    },
    //余额、企业数量、坐席数量
    getList() {
      let data = JSON.parse(localStorage.getItem("dataForm"));
      // if(data == null && this.realname != 'admin') location.reload();
      // console.log("data",data);
      data ? (this.dataForm = data) : (this.dataForm = {});
    },
  },
};
</script>
